<template>
    <v-row no-gutters justify="space-between" class="my-8">
        <v-col v-if="editMarketplace == undefined " cols="12" md="10">
            <!-- this.$router.history.current.path -->
            <h4>
                {{
                    this.$route.name == 'Marketplace' ? this.$t("message.marketplace") : this.$t("message.label-marketplace_group")
                }}
            </h4>
        </v-col>
        <v-col cols="12" md="2">
            <div class="mx-12">

                <v-row justify="center">
                    <v-dialog
                        v-model="dialog"
                        persistent
                        max-width="600px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-if="editMarketplace != undefined">
                            <v-btn rounded color="primary" v-bind="attrs" v-on="on" @click="getMarketplace(editMarketplace)">
                                {{ $t("message.label-edit-item") }}
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn color="primary" dark v-bind="attrs" v-on="on">
                                <v-icon left>
                                    mdi-plus
                                </v-icon>                            
                                {{ $t("message.create") }}
                            </v-btn>

                        </div>
                    </template>
                    <v-card>
                        <v-form @submit.prevent="submitFormMarketplace()">
                            <v-row no-gutters>
                                <v-col v-if="editMarketplace == undefined" cols="12" md="7">
                                    <v-card-title>
                                    <span class="" style="font-size: 16px">
                                        {{
                                            this.$route.name == "Marketplace" ? $t("message.add_marketplace") : $t("message.label-add_marketplace_group")
                                        }}
                                    </span>
                                    </v-card-title>
                                </v-col>
                                <v-col v-else cols="12" md="7">
                                    <v-card-title>
                                    <span class="" style="font-size: 16px">
                                        {{
                                            this.$route.name == "Marketplace" ? $t("message.update_marketplace") : $t("message.label-update_marketplace_group")
                                        }}
                                    </span>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <div class="grid-button-submit"> 

                                        <div class="mx-2 my-2">
                                            <v-btn @click="dialog = false" block color="secondary">
                                            {{ $t("message.back-button") }}
                                            </v-btn>
                                        </div>

                                        <div class="mx-2 my-2">
                                            <v-btn color="primary" block type="submit" @submit.prevent="submitFormMarketplace">
                                            {{ $t("message.submit-button") }}
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-card-text>
                                <v-container>
                                    <v-row no-gutters> 
                                        <v-col v-if="this.$route.name == 'Marketplace'" cols="12" md="2" xs="2">
                                            <span>
                                                {{ $t("message.marketplace_name") }}
                                            </span>
                                        </v-col>
                                        <v-col v-if="this.$route.name == 'Marketplace'" cols="12" md="10" xs="2">
                                            <v-text-field
                                                v-model="form.marketplace_name"
                                                required
                                                outlined
                                                :error-messages="marketplaceNameErrors"
                                                @input="$v.form.marketplace_name.$touch()"
                                                @blur="$v.form.marketplace_name.$touch()"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col v-if="this.$route.name != 'Marketplace'" cols="12" md="2" xs="2">
                                            <span>
                                                {{ $t("message.label-marketplace_group_name") }}
                                            </span>
                                        </v-col>
                                        <v-col v-if="this.$route.name != 'Marketplace'" cols="12" md="10" xs="2">
                                            <v-text-field
                                                v-model="form.marketplace_group_name"
                                                required
                                                outlined
                                                :error-messages="marketplaceGroupNameErrors"
                                                @input="$v.form.marketplace_group_name.$touch()"
                                                @blur="$v.form.marketplace_group_name.$touch()"
                                            ></v-text-field>
                                        </v-col>

                                        <!-- CONDITIONAL IF ROUTE FORM ADD MARKETPLACE -->
                                        <v-col v-if="this.$route.name == 'Marketplace'" cols="12" md="2" xs="2">
                                            <span>{{ $t("message.label-marketplace_group_name") }}</span>
                                        </v-col>
                                        <v-col v-if="this.$route.name == 'Marketplace'" cols="12" md="10" xs="2">
                                            <v-select
                                            v-model="form.marketplace_group_id"
                                            :items="listmarketplaceGroup"
                                            item-text="marketplace_group_name"
                                            item-value="id"
                                            required
                                            outlined
                                            :error-messages="marketplaceGroupErrors"
                                            @input="$v.form.marketplace_group_id.$touch()"
                                            @blur="$v.form.marketplace_group_id.$touch()"
                                            ></v-select>
                                        </v-col>
                                        
                                        <v-col cols="12" md="2" xs="2">
                                            <span>{{ $t("message.images") }}</span>
                                        </v-col>
                                        <v-col cols="12" md="10" xs="2">
                                            <v-file-input
                                                v-if="this.form.image_url == null"
                                                v-model="form.images"
                                                persistent-placeholder
                                                height="120px"
                                                accept="image/*"
                                                id="file-input"
                                                ref="fileImg"
                                                :prepend-icon="null"
                                                style="display: none; visibility: none; cursor: pointer; display: flex; justify-content: center; align-items: center"
                                                :prepend-inner-icon="this.form.image_url == null ? 'mdi-plus-circle-outline' : '' "
                                                type="file"
                                                @change="upload"
                                                outlined
                                                :disabled="this.form.image_url != null"
                                                hide-details="false"
                                                
                                            ></v-file-input>
                                            <v-row v-if="isLoadingImg" class="fill-height ma-0 mt-8">
                                                <v-progress-circular
                                                indeterminate
                                                color="blue"
                                                ></v-progress-circular>
                                            </v-row>

                                            <template>
                                                <div class="set-img">
                                                    <v-img
                                                        style="border-radius: 15px"
                                                        :lazy-src="this.form.image_url"
                                                        :src="this.form.image_url"
                                                        width="1024px"
                                                        height="120px"
                                                    >
                                                    <v-icon v-if="this.form.image_url != null" class="icon-close" @click.prevent="deleteImg()">
                                                        mdi-close-circle-outline
                                                    </v-icon>
                                                    </v-img>
                                                </div>
                                            </template>
                                        </v-col>
                                        
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-form>
                    </v-card>
                    </v-dialog>
                </v-row>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import {

  required,
  minLength,
  numeric,
  minValue,
  decimal
} from "vuelidate/lib/validators";
export default {
    name: 'FormGroupMarketplace',
    props: ["storeId", "editMarketplace"],
    mixins: [validationMixin],
    validations: {
        form: {
            marketplace_name: { required },
            marketplace_group_name: { required },
            marketplace_group_id: { required }
        }
    },
    data() {
        return {
            dialog: false,
            form: {
                is_active: 0,
                marketplace_name: "",
                marketplace_group_name: "",
                marketplace_group_id: "",
                images: null,
                image_url: null
            },
            isLoadingImg: false,
            storeId_edit: "",
            itemId: ""
        }
    },
    methods: {
        getMarketplace(editData) {

            this.form.marketplace_name = editData.marketplace_name
            this.form.marketplace_group_name = editData.marketplace_group_name
            this.form.marketplace_group_id = editData.marketplace_group_id
            this.form.image_url = editData.image_url
            this.storeId_edit = editData.store_id
            this.itemId = editData.id
            this.form.is_active
        },
        async submitFormMarketplace() {
            let payload = {
                id: this.itemId,
                marketplace_group_name: this.form.marketplace_group_name,
                marketplace_name: this.form.marketplace_name,
                marketplace_group_id: this.form.marketplace_group_id,
                store_id: this.storeId === undefined ? this.storeId_edit : this.storeId ,
                image_url: this.form.image_url,
                is_active: this.form.is_active
            }
            this.$emit("on-submit", payload)    
            
            this.form.marketplace_name = "";
            this.form.marketplace_group_name = "";
        },
        deleteImg() {
            if(this.form.image_url != null) {
                this.$store.dispatch("marketplace_module/deleteImageState", 0 );
                this.$store.dispatch('uploadImage_module/deleteImgBucket', {
                    path: this.form.image_url
                });
                this.form.image_url = null
                this.form.images = null
                this.form.marketplace_name = "";
                this.form.marketplace_group_name = "";
            }

        },
        async upload() {
            this.isLoadingImg = true
            if(this.form.images != null) {
                await this.$store.dispatch("marketplace_module/uploadImage", this.form.images)
                let uploadLinkImg = this.$store.state.marketplace_module.marketplace_image;
                this.form.image_url = uploadLinkImg;
            } else {
                this.form.image_url = null
            }
            this.isLoadingImg = false
        },
        
    },
    mounted() {
        this.$store.dispatch("marketplace_module/clearImageMarketplace");
    },
    created() {
        
    },
    computed: {
        listmarketplaceGroup() {
            return this.$store.state.marketplace_module.marketplace_group_list
        },
        marketplaceGroupErrors() {
            const errors = [];
            if (!this.$v.form.marketplace_group_id.$dirty) return errors;
            !this.$v.form.marketplace_group_id.required &&
                errors.push(this.$t("message.err-msg-required-marketplace_group"));
            return errors;
        },
        marketplaceNameErrors() {
            const errors = [];
            if (!this.$v.form.marketplace_name.$dirty) return errors;
            !this.$v.form.marketplace_name.required &&
                errors.push(this.$t("message.err-msg-required-marketplace_name"));
            return errors;
        },
        marketplaceGroupNameErrors() {
            const errors = [];
            if (!this.$v.form.marketplace_group_name.$dirty) return errors;
            !this.$v.form.marketplace_group_name.required &&
                errors.push(this.$t("message.err-msg-required-marketplace_group_name"));
            return errors;
        }
    }
}
</script>

<style>
.grid-button-submit {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, auto));
}
</style>