<template>
    <v-container>

        <FormGroupMarketplace @on-submit="submitAddMarketplaceGroup" :storeId="this.store" />

        <div>
            <v-form>
                <v-row no-gutters>
                    <v-col cols="12" md="1" xs="2">
                        <span>{{ $t("message.shop") }}</span>
                    </v-col>
                    <v-col cols="12" md="2" xs="2">
                        <v-autocomplete
                            :items="shopList"
                            v-model="store"
                            @change="changeShop"
                            outlined
                            required
                            item-text="store_name"
                            item-value="id"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
        </div>

        <!-- data table -->
        <v-data-table
        :headers="headers"
        :items="marketplaceGroupList"
        disable-sort
        class="elavation-0"
        :hide-default-footer="true"
        hide-default-header
        disable-pagination
        >
        <template v-slot:header="{ props }" style="min-height: 50px">
            <th class="custom-th text-center">
            {{ props.headers[0].text }}
            </th>
            <th class="custom-th text-center">
            {{ props.headers[1].text }}
            </th>
            <th class="custom-th text-center">
            {{ props.headers[2].text }}
            </th>
            <th class="custom-th text-center">
            {{ props.headers[3].text }}
            </th>
            <th class="custom-th text-center">
            {{ props.headers[4].text }}
            </th>
            <th class="custom-th text-center">
            {{ props.headers[5].text }}
            </th>
        </template>

        <!-- imageproduct itemlist loop -->
        <template v-slot:[`item.image_url`]="{ item }" class="text-center">
            <div class="d-flex justify-center">
            <v-img
                :lazy-src="item.image_url ? item.image_url : notFound"
                :src="item.image_url ? item.image_url : notFound"
                max-height="100px"
                max-width="330px"
                contain
                style="border-radius: 15px; margin: 1em 0 1em 0; border: 1px solid black"
            ></v-img>
            </div>
        </template>

        <template v-slot:[`item.edit`]="{ item }">
            <div>
                <FormGroupMarketplace :editMarketplace="item" @on-submit="submitEditForm" />
            </div>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
            <div>
                <v-btn rounded color="grey" @click="showDelete(item)">
                    {{ $t("message.btn-delete") }}
                </v-btn>
            </div>
        </template>

        <template v-slot:[`item.is_active`]="{ item }">

            <div>
                <v-switch
                    color="primary"
                    @change="is_publish(item)"
                    :input-value="item.is_active"
                >
                </v-switch>
            </div>

        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
            <!-- <div v-if="item.has_recommended == 1"> -->
            <div v-if="index != 0" class="d-flex justify-center align-center">
                <v-icon
                    size="35"
                    @click="upMarketplaceGroup(item)"
                >
                mdi-arrow-up-thin
                </v-icon>
            </div>
            <div v-if="item.id != itemLastMarketplace.id" class="d-flex justify-center align-center">
                <v-icon
                    size="35"
                    @click="downMarketplaceGroup(item)"
                >
                mdi-arrow-down-thin
                </v-icon>
            </div>
        </template>
        </v-data-table>
    </v-container>
</template>

<script>
import FormGroupMarketplace from '../../components/developmentv2/FormGroupMarketplace.vue'
export default {
    name: "MarketplaceGroup",
    components: {
        FormGroupMarketplace
    },
    data() {
        return {
            notFound: "",
            dialog: false,
            store: "",
            isLoading: false
        }
    },
    methods: {
        submitEditForm(payload) {
            let tempDataEdit = {
                image_url: payload.image_url,
                store_id: payload.store_id,
                marketplace_group_name: payload.marketplace_group_name,
                is_active: payload.is_active
            }
            return this.$store.dispatch("marketplace_module/updateMarketplaceGroup", {
                tempDataEdit,
                id: payload.id
            })
        },
        submitAddMarketplaceGroup(payload) {
            delete payload.marketplace_group_id
            delete payload.id
            let result = {
                store_id: payload.store_id,
                marketplace_group_name: payload.marketplace_group_name,
                image_url: payload.image_url,
            }
            return this.$store.dispatch("marketplace_module/addMarketplaceGroup", result)
        },
        async upMarketplaceGroup(item) {
            this.isLoading = true
            await this.$store.dispatch("marketplace_module/setUpMarketplaceGroup", {
                id: item.id,
                storeId: item.store_id
            })
            this.isLoading = false
        },
        async downMarketplaceGroup(item) {
            this.isLoading = true
            await this.$store.dispatch("marketplace_module/setDownMarketplaceGroup", {
                id: item.id,
                storeId: item.store_id
            })
            this.isLoading = false
        },
        async is_publish(item) {
            await this.$store.dispatch("marketplace_module/statusMarketplaceGroup", {
                id: item.id,
                storeId: item.store_id
            })
        },
        async showDelete(item) {
            await this.$store.dispatch("marketplace_module/deleteMarketplaceGroup", {
                id: item.id,
                storeId: item.store_id
            })
        },
        async initMarketplaceGroup() {
            await this.$store.dispatch("shop_module/fetchShopAll");
            let shopDefault = this.$store.state.shop_module.shopAllResult;
            this.store = shopDefault[0].id;

            return this.$store.dispatch("marketplace_module/listMarketplaceGroup", this.store)
        },
        async changeShop() {
            await this.$store.dispatch("marketplace_module/marketplaceByStore", this.store)
        }
    },
    created() {
        this.initMarketplaceGroup();
    },
    computed: {
        shopList() {
            let shopDefault = this.$store.state.shop_module.shopAllResult;
            return shopDefault;
        },
        marketplaceGroupList() {
            return this.$store.state.marketplace_module.marketplace_group_list
        },
        itemLastMarketplace() {
            return this.$store.state.marketplace_module.lastItemMarketplaceGroup;
        },
        headers() {
            return [
                {
                text: this.$t("message.label-marketplace_group_name"),
                value: "marketplace_group_name",
                width: "4%",
                },
                {
                text: this.$t("message.image"),
                value: "image_url",
                align: "center",
                width: "13%",
                },
                { text: this.$t("message.label-edit-item"), value: "edit", width: "0%" },
                { text: this.$t("message.label-delete-item"), value: "delete", width: "0%" },
                { text: this.$t("message.publish"), value: "is_active", width: "0%" },
                { text: this.$t("message.action"), value: "actions", width: "0%" },

            ];
        },
    },

}
</script>

<style scoped>

.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
.image-url-class {
  margin: 10px auto !important
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-application .text-start,
tr td {
  text-align: center !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
.grid-button-submit {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10px, auto));
}
</style>